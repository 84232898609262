import React from "react";
import { format, isDate } from "date-fns";
import {FreepayrollButton, Typography, Modal} from "@collegia-partners/ui-kit";
import styles from "./styles.module.scss";

const ListItem = ({textInfo, schedulePropriety}) => {
	return (
		<div className={styles.ListItem}>
			<Typography variant={"body"} size={"medium"} color={"black"}>
				• <strong>{textInfo}</strong> {schedulePropriety}
			</Typography>
		</div>
	)
}

export const PayScheduleConfirmDialog = ({
	open = false,
	schedule = {},
	setOpen = () => {},
	addPaySchedule = () => {},
	isOnboarding,
	push,
	isBureau = false,
}): JSX.Element => {
	return (
		<Modal
			onClose={() => setOpen(false)}
			isOpen={open}
			size={"md"}
		>
			<div className={styles.PayScheduleConfirmDialog}>
				<Typography variant={"title"} size={"large"} color={"primary"} weight={"bold"}>
					Confirm Your Payment Schedule
				</Typography>
				<Typography variant={"body"} size={"xmedium"} color={"black"} weight={"bold"}>
					Your first pay run will be on:
				</Typography>
				<div className={styles.List}>
					<ListItem textInfo={"Pay frequency: "} schedulePropriety={schedule?.scheduleName}/>
					<ListItem textInfo={"First Payroll Date: "} schedulePropriety={isDate(schedule?.firstPayDate) ? format(schedule?.firstPayDate, "dd/MM/yyyy") : ""}/>
					<ListItem textInfo={"Tax Period: "} schedulePropriety={schedule?.taxPeriod}/>
					<ListItem textInfo={"Day rate method: "} schedulePropriety={schedule?.dayRateMethod === "yearly_working_days" ? "Yearly Working Days" : "Monthly Working Days"}/>
					{
						isBureau &&
						<>
							<ListItem textInfo={"Automatic pay schedule: "} schedulePropriety={schedule?.automaticPaySchedule ? "Yes" : "No"}/>
							<ListItem textInfo={"Require client authorisation: "} schedulePropriety={schedule?.clientAuthorisation ? "Yes" : "No"}/>
							{
								schedule?.clientAuthorisation &&
								<>
									<ListItem textInfo={"Authoriser name: "} schedulePropriety={schedule?.authoriserName}/>
									<ListItem textInfo={"Authoriser email: "} schedulePropriety={schedule?.authoriserEmail}/>
									<ListItem textInfo={"Close pay run upon client"} schedulePropriety={schedule?.closePayRun ? "Yes" : "No"}/>
								</>
							}
						</>
					}
				</div>
				<Typography variant={"body"} size={"small"} color={"black"}>
					<strong>Note:</strong> If you want your first payroll to start earlier, please click on cancel and change your first payment date.
				</Typography>
				<div
					className={styles.ButtonsDiv}
				>
					<FreepayrollButton
						onClick={() => setOpen(false)}
						variant={"outline"}
						size={"medium"}
					>
						Cancel
					</FreepayrollButton>
					<FreepayrollButton
						variant={"primary"}
						size={"medium"}
						onClick={() =>
							addPaySchedule({
								"pay_schedules": {
									name: schedule?.scheduleName,
									frequency_code: schedule?.payFrequency,
									week_payday: (schedule?.payFrequency === "W1" || schedule?.payFrequency === "W2" || schedule?.payFrequency === "W4") ? schedule?.payDays : null,
									month_payday: ((schedule?.payFrequency === "M1") && (schedule?.payDays !== 'Last Day of Month')) ? schedule?.payDays : null,
									custom_payday: (schedule?.payFrequency === "M1") && (schedule?.payDays === 'Last Day of Month') ? schedule?.payDays : null,
									starting_period_end_date: isDate(schedule?.firstPayDate) ? format(schedule?.firstPayDate, "yyyy-MM-dd") : "",
									day_rate_method: schedule?.dayRateMethod,
									automatic_pay_schedule: schedule?.automaticPaySchedule,
									require_client_authorisation: schedule?.clientAuthorisation,
									close_upon_approval: schedule?.closePayRun,
									authoriser_name: schedule?.authoriserName,
									authoriser_email: schedule?.authoriserEmail,
								},
							}, isOnboarding, push)
						}
					>
						Save
					</FreepayrollButton>
				</div>
			</div>
		</Modal>
	)
}