import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import {
	Divider,
	InputLabel,
	MenuItem,
	Select,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import enGb from "date-fns/locale/en-GB";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { endOfMonth, parseISO } from "date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import 'react-calendar/dist/Calendar.css';
import Help from "../../../../images/svg/help-icon.svg"
import { useNavigate } from "react-router-dom";
import {resolveTaxPeriodAndPeriodDates} from "../../../../utils/Helpers";
import {PayScheduleConfirmDialog} from "../../PaySchedule/PayScheduleConfirmDialog/PayScheduleConfirmDialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {FreepayrollButton, FreepayrollTextField, Typography} from "@collegia-partners/ui-kit";
import DayRateMethodDialog from "../../../Pay Schedules/DayRateMethodDialog";
import RunPayScheduleAutomaticDialog from "../../../Pay Schedules/RunPayScheduleAutomaticDialog";

export function getPayDayOption(payFrequency = "") {
	switch (payFrequency) {
		case "W1":
		case "W2":
		case "W4":
			return [
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
			];
		case "M1":
			return [
				"1",
				"2",
				"3",
				"4",
				"5",
				"6",
				"7",
				"8",
				"9",
				"10",
				"11",
				"12",
				"13",
				"14",
				"15",
				"16",
				"17",
				"18",
				"19",
				"20",
				"21",
				"22",
				"23",
				"24",
				"25",
				"26",
				"27",
				"28",
				"29",
				"30",
				"Last Day of Month",
			];
		default:
			return [];
	}
}

function disableDayOption(payFrequency = "", payDays, date) {
	switch (payFrequency) {
		case "W1":
		case "W2":
		case "W4":
			if (payDays === "Friday") {
				return date.getDay() !== 5;
			} else if (payDays === "Thursday") {
				return date.getDay() !== 4;
			} else if (payDays === "Wednesday") {
				return date.getDay() !== 3;
			} else if (payDays === "Tuesday") {
				return date.getDay() !== 2;
			} else if (payDays === "Monday") {
				return date.getDay() !== 1;
			}
			break;
		case "M1":
			if (payDays === "Last Day of Month") {
				return endOfMonth(date).getDate() !== date.getDate();
			}
			if (date.getMonth() === 1) {
				if (Number(payDays) > endOfMonth(date).getDate()) {
					return endOfMonth(date).getDate() !== date.getDate();
				}
			}
			return date.getDate() !== Number(payDays);
		default:
			return [];
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PayScheduleInformation = ({
	addPaySchedule = () => { },
	employer,
	isOnboarding,
	user = {},
	setStep = () => {},
	schedule = {},
	setSchedule = () => {},
}): JSX.Element => {
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [warningDialog, setWarningDialog] = useState(false);
	const [openInfoModal, setOpenInfoModal] = useState(false);
	const push = useNavigate();

	useEffect(() => {
		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, []);

	return (
		<Grid
			container
		>
			{/*Page Title*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
			>
				<Grid>
					<Typography variant={"title"} size={"large"} color={"primary"} weight={"bold"}>
						Create a new pay schedule
					</Typography>
				</Grid>
			</Grid>

			<Grid
				paddingTop={"30px"}
				paddingBottom={"50px"}
				width={"100%"}
			>
				<Divider />
			</Grid>

			{/*Pay Schedule Information*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				direction={"row"}
				alignContent={"flex-start"}
				spacing={4}
			>
				{/*Pay Schedule Name*/}
				<Grid
					xl={4.5}
					lg={4.5}
					md={6.5}
					sm={6.5}
					xs={6.5}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Schedule name*
					</InputLabel>
					<FreepayrollTextField
						placeholder={"Type Schedule name"}
						name={"scheduleName"}
						fieldType={"text"}
						value={schedule?.scheduleName}
						onChange={(e) => {
							schedule.scheduleName = e.target.value;
							setSchedule(schedule);
						}}
						noGhostLabel
						noGhostHelperText
					/>
				</Grid>

				{/*Pay Frequency*/}
				<Grid
					xl={4.5}
					lg={4.5}
					md={6.5}
					sm={6.5}
					xs={6.5}
					item
					container
					alignItems={"center"}
				>
					<InputLabel
						className={"default-input-label"}
					>
						Pay frequency*
					</InputLabel>
					<Select
						className={"default-text-field"}
						MenuProps={{
							PaperProps: {
								style: {
									maxHeight: 200, // Adjust the maximum height of the list
								},
							},
						}}
						fullWidth
						value={schedule?.payFrequency}
						onChange={(e) => {
							schedule.payFrequency = e.target.value;
							schedule.payDays = "Select Pay Day";
							schedule.firstPayDate = null;
							setSchedule(schedule);
						}}
					>
						{
							<MenuItem
								value={"Select Pay frequency"}
								key={""}
								disabled
							>
								Select Pay frequency
							</MenuItem>
						}
						{
							schedule?.payFrequencyOption.map((option, index = {}) => (
								<MenuItem key={index} value={option.code}>
									{option.name}
								</MenuItem>
							))
						}
					</Select>
				</Grid>

				{/*Paydays*/}
				{
					(schedule?.scheduleName.length > 0 && schedule?.payFrequency !== "Select Pay frequency") && (
						<Grid
							xl={4.5}
							lg={4.5}
							md={6.5}
							sm={6.5}
							xs={6.5}
							item
							container
							alignItems={"center"}
						>
							<InputLabel
								className={"default-input-label"}
							>
								Paydays*
							</InputLabel>
							<Select
								className={"default-text-field"}
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: 200, // Adjust the maximum height of the list
										},
									},
								}}
								value={schedule?.payDays}
								fullWidth
								onChange={(e) => {
									schedule.payDays = e.target.value;
									schedule.firstPayDate = null;
									setSchedule(schedule);
								}}
							>
								<MenuItem value={"Select Pay Day"} key={""} disabled>
									Select Pay Day
								</MenuItem>
								{
									getPayDayOption(schedule?.payFrequency).map((option, index) => (
										<MenuItem key={index} value={option}>
											{option}
										</MenuItem>
									))
								}
							</Select>
						</Grid>
					)
				}

				{/*First Pay Date*/}
				{
					(schedule?.scheduleName.length > 0 && schedule?.payFrequency !== "Select Pay frequency") && (
						<Grid
							xl={4.5}
							lg={4.5}
							md={6.5}
							sm={6.5}
							xs={6.5}
							item
						>
							<InputLabel
								className={"default-input-label"}
							>
								First pay date*
							</InputLabel>
							<LocalizationProvider
								dateAdapter={AdapterDateFns}
								adapterLocale={enGb}
							>
								<DesktopDatePicker
									className={"date-picker"}
									onChange={(newValue) => {
										schedule.firstPayDate = newValue;
										setSchedule(schedule);
										if (schedule?.payFrequency !== "Select Pay frequency" && newValue != null) {
											schedule.taxPeriod = resolveTaxPeriodAndPeriodDates(
												schedule?.payFrequency,
												newValue,
												schedule?.payDays === "Last Day of Month"
											)["tax_period"];
											setSchedule(schedule);
										}
									}}
									value={schedule?.firstPayDate}
									shouldDisableDate={(day) => {
										return disableDayOption(schedule?.payFrequency, schedule?.payDays, day);
									}}
									slotProps={{ field: { readOnly: true } }}
									enableAccessibleFieldDOMStructure
									minDate={parseISO(employer?.tax_year?.from)}
									maxDate={parseISO(employer?.tax_year?.to)}
								/>
							</LocalizationProvider>
						</Grid>
					)
				}

				{/*Day rate method*/}
				{
					(schedule?.scheduleName.length > 0 && schedule?.payFrequency !== "Select Pay frequency") && (
						<Grid
							xl={4.5}
							lg={4.5}
							md={6.5}
							sm={6.5}
							xs={6.5}
							item
							container
						>
							<InputLabel
								className={"default-input-label"}
							>
								Day rate method*
							</InputLabel>
							<img
								src={Help}
								alt={"help"}
								style={{
									width: "17px",
									height: "17px",
									marginLeft: "7px",
									cursor: "pointer",
								}}
								onClick={() => setOpenInfoModal(true)}
							/>
							<Select
								className={"default-text-field"}
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: 200, // Adjust the maximum height of the list
										},
									},
								}}
								fullWidth
								value={schedule?.dayRateMethod}
								onChange={(e) => {
									schedule.dayRateMethod = e.target.value;
									setSchedule(schedule);
								}}
							>
								<MenuItem
									value={"yearly_working_days"}
									key={"yearly_working_days"}
								>
									Yearly working days
								</MenuItem>
								<MenuItem
									value={"calendar_month"}
									key={"calendar_month"}
								>
									Calendar Month
								</MenuItem>
							</Select>
						</Grid>
					)
				}
				{/*Automatic schedule*/}
				{
					(schedule?.scheduleName.length > 0 && schedule?.payFrequency !== "Select Pay frequency" &&
						user?.is_bureau_user) &&
					<Grid
						xl={4.5}
						lg={4.5}
						md={6.5}
						sm={6.5}
						xs={6.5}
						item
						container
					>
						<FormControlLabel
							id={"pay-schedule-switch"}
							control={
								<Switch
									sx={{ marginLeft: 'auto' }}
									checked={schedule?.automaticPaySchedule}
									onClick={() => {
										schedule.automaticPaySchedule = !schedule.automaticPaySchedule;
										setSchedule(schedule);
										if (schedule.automaticPaySchedule) {
											setWarningDialog(true);
										}
									}}
								/>
							}
							label={
								<Typography variant={"body"} size={"medium"} color={"black"} weight={"bold"}>
									Run pay schedule on automatic
								</Typography>
							}
							sx={{marginLeft: 0}}
						/>
					</Grid>
				}
			</Grid>

			<div className={"pay-schedule-information-button-2"}>
				<FreepayrollButton
					variant={"primary"}
					size={"medium"}
					onClick={() =>
						user?.is_bureau_user
							? setStep(2)
							: setOpenConfirmDialog(true)
					}
					disabled={
						schedule?.scheduleName.length === 0 ||
						schedule?.payFrequency.length === 0 ||
						schedule?.payDays === "Select Pay Day" ||
						schedule?.firstPayDate === null
					}
				>
					Continue
				</FreepayrollButton>
			</div>

			<DayRateMethodDialog
				openInfoModal={openInfoModal}
				setOpenInfoModal={setOpenInfoModal}
			/>

			<PayScheduleConfirmDialog
				open={openConfirmDialog}
				schedule={schedule}
				setOpen={setOpenConfirmDialog}
				addPaySchedule={addPaySchedule}
				isOnboarding={isOnboarding}
				push={push}
			/>

			<RunPayScheduleAutomaticDialog
				schedule={schedule}
				warningDialog={warningDialog}
				setSchedule={setSchedule}
				setWarningDialog={setWarningDialog}
			/>
		</Grid>
	);
}

export default PayScheduleInformation;