import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { me } from "../../actions/Profile";
import { LeftMenu } from "../../components/LeftMenu";
import { StyleAppBar } from "../../components/StyleAppBar";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Loading from "../../components/Loading";
import { useNavigate } from "react-router-dom";
import { Chip, MenuItem } from "@mui/material";
import {
	downloadPayrollReport,
	downloadPayslips,
	getPayScheduleRuns,
	reopenPayroll,
	sendPayslip,
} from "../../actions/PayScheduleRun";
import StyleTable from "../../components/StyledComponents/StyleTable";
import StyleButton from "../../components/StyledComponents/StyleButton";
import { ReopenPayRun } from "../../components/Pages/ReopenPayrun";
import Menu from "@mui/material/Menu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import { findEmployer } from "../../actions/Employer";
import {
	formatDateFromBackend,
	formatDateFromBackendWithTime,
} from "../../utils/Helpers";

function renderPayRunStatusColor(status) {
	switch (status) {
		case "Submitted":
			return {
				backgroundColor: "#0360FD",
				color: "#FFFFFF",
			};
		default:
			return {
				backgroundColor: "#C4C4C4",
				color: "#FFFFFF",
			};
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PayrollHistoryPage = ({
	me = () => {},
	getPayScheduleRuns = () => {},
	user = {
		id: null,
	},
	pay_schedule_runs = [],
	pay_schedule_run_loading = false,
	user_loading = false,
	reopenPayroll = () => {},
	sendPayslip = () => {},
	downloadPayslips = () => {},
	findEmployer = () => {},
	employer = {},
	downloadPayrollReport = () => {},
}): JSX.Element => {
	const push = useNavigate();
	const [openModal, setOpenModal] = useState(false);
	const [payrollId, setPayrollId] = useState(null);
	const [openMenu, setOpenMenu] = useState(false),
		[anchorEl, setAnchorEl] = useState(null);
	const columns = [
		{
			name: "pay_schedule.name",
			label: "Schedule Name",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={"bold"}
					>
						{value}
					</StyleTypography>
				),
			},
		},
		{
			name: "tax_period",
			label: "Tax Period",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={"bold"}
					>
						{value}
					</StyleTypography>
				),
			},
		},
		{
			name: "pay_run_period",
			label: "Pay Run",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={"bold"}
					>
						{value}
					</StyleTypography>
				),
			},
		},
		{
			name: "period_end_date",
			label: "Pay Date",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={"bold"}
					>
						{formatDateFromBackendWithTime(value)}
					</StyleTypography>
				),
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				filter: true,
				sort: false,
				setCellProps: () => ({ className: "font-size" }),
				customBodyRender: (value) => (
					<Chip
						label={value}
						className={"mui-chip"}
						sx={renderPayRunStatusColor(value)}
					/>
				),
			},
		},
		{
			name: "can_be_rolled_back",
			label: "can_be_rolled_back",
			options: {
				display: false,
				filter: false,
			},
		},
		{
			name: "last_completed_at",
			label: "Submitted Date",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={"bold"}
					>
						{formatDateFromBackend(value)}
					</StyleTypography>
				),
			},
		},
		{
			name: "id",
			label: " ",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (_, tableMeta) => (
					<Grid
						container
						alignContent={"center"}
						alignItems={"center"}
						justifyContent={"space-between"}
					>
						<Menu
							open={openMenu}
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							onClose={() => {
								setOpenMenu(false);
								setAnchorEl(null);
								setPayrollId(null);
							}}
						>
							<MenuItem
								onClick={() => push(`/main/view-payroll/${btoa(payrollId)}`)}
							>
								View Payroll
							</MenuItem>
							<MenuItem
								onClick={() => {
									sendPayslip({
										pay_schedule_runs: {
											bulk_send: true,
											id: payrollId,
										},
									});
									setOpenMenu(false);
								}}
							>
								Email all payslips
							</MenuItem>
							<MenuItem
								onClick={() => {
									downloadPayslips({
										pay_schedule_runs: {
											action: "payrun_download",
											id: payrollId,
										},
									});
									setOpenMenu(false);
								}}
							>
								Download all payslips
							</MenuItem>
							<MenuItem
								onClick={() => {
									downloadPayrollReport({
										pay_schedule_runs: {
											id: payrollId,
										},
									});
									setOpenMenu(false);
								}}
							>
								Download payroll report
							</MenuItem>
						</Menu>
						{tableMeta.rowData[5] === true && (
							<Grid>
								<StyleButton
									backgroundColor={"red"}
									onClick={() => {
										setPayrollId(tableMeta.rowData[7]);
										setOpenModal(true);
									}}
									disabled={employer?.account_locked}
									border="none"
								>
									<StyleTypography
										fontSize={14}
										fontSizeMedium={11}
										color={"#FFFFFF"}
										fontWeight={"bold"}
									>
										Reopen Payroll
									</StyleTypography>
								</StyleButton>
							</Grid>
						)}
						<Grid>
							<IconButton
								onClick={(event) => {
									setAnchorEl(event.currentTarget);
									setOpenMenu(true);
									setPayrollId(tableMeta.rowData[7]);
								}}
							>
								<MoreHorizIcon />
							</IconButton>
						</Grid>
					</Grid>
				),
			},
		},
	];

	useEffect(() => {
		if (!user.id) {
			me();
		}

		findEmployer(["employer_tax_years"]);

		document.documentElement.style.setProperty(
			"--scroll-height",
			`${document.body.scrollHeight}`
		);
	}, [findEmployer, me, user.id]);

	useEffect(() => {
		getPayScheduleRuns({
			pay_schedule_runs: {
				page: "payroll_history",
			},
		});
	}, [getPayScheduleRuns]);

	if (pay_schedule_run_loading || user_loading) {
		return <Loading />;
	}

	return (
		<Grid container justifyContent={"center"} id={"main-page"}>
			<StyleAppBar showBackButton={false} user={user} />
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu activePage={"Payroll"} activeSubpage={"History"} />
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid container>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							className={"team-member-table-container"}
						>
							<StyleTable
								title={"Submitted Pay Runs"}
								columns={columns}
								data={pay_schedule_runs ?? []}
								options={{
									selectableRowsHeader: false,
									selectableRows: "none",
									responsive: "simple",
									enableNestedDataAccess: ".",
									selectToolbarPlacement: "none",
									searchAlwaysOpen: false,
									print: false,
									download: false,
									search: true,
									viewColumns: false,
									elevation: 0,
									filter: true,
									searchProps: {
										placeholder: "Search pay runs",
										classes: {
											input: "default-text-field",
										},
									},
								}}
								removeToolbar={false}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<ReopenPayRun
				open={openModal}
				setOpen={setOpenModal}
				reopenPayroll={reopenPayroll}
				payrollId={payrollId}
			/>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	const { Profile, PayScheduleRun, Employer } = state;

	return {
		...Profile,
		...PayScheduleRun,
		...Employer,
	};
};

const mapDispatchToProps = (dispatch) => ({
	me: () => dispatch(me()),
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
	getPayScheduleRuns: (data) => dispatch(getPayScheduleRuns(data)),
	reopenPayroll: (data, push) => dispatch(reopenPayroll(data, push)),
	sendPayslip: (data) => dispatch(sendPayslip(data)),
	downloadPayslips: (data) => dispatch(downloadPayslips(data)),
	downloadPayrollReport: (data) => dispatch(downloadPayrollReport(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayrollHistoryPage);
