import Login from "./Login";
import SignUp from "./SignUp";
import ConfirmEmail from "./ConfirmEmail";
import OnboardingPages from "./Onboarding";
import PeoplePages from "./People";
import MainPage from "./MainPage";
import CompanyPages from "./Company";
import HMRC from "./HMRC & Compliance/HMRC";
import RunPayroll from "./Payroll/RunPayroll";
import ErrorPages from "./Errors";
import ForgotPassword from "./ForgotPassword";
import SettingsPages from "./Settings";
import ReportsPage from "./Reports";
import PayrollPages from "./Payroll";
import ViewPayroll from "./Payroll/ViewPayroll";
import AEPages from "./Auto Enrolment";
import Tutorials from "./Tutorials";
import CloseTaxYear from "./CloseTaxYear";
import AutoEnrolment from "./AutoEnrolment";

const Pages = {
	AutoEnrolment,
	Login,
	SignUp,
	ForgotPassword,
	ConfirmEmail,
	PeoplePages,
	OnboardingPages,
	MainPage,
	CompanyPages,
	HMRC,
	AEPages,
	RunPayroll,
	ErrorPages,
	SettingsPages,
	ReportsPage,
	PayrollPages,
	ViewPayroll,
	Tutorials,
	CloseTaxYear,
};

export default Pages;
