import Grid from "@mui/material/Grid";
import React from "react";
import WhiteAndBlueWarning from "../../images/svg/white-and-blue-warning.svg";
import StyleTypography from "../StyledComponents/StyleTypography";
import {FreepayrollButton, Modal} from "@collegia-partners/ui-kit";

const RunPayScheduleAutomaticDialog = ({
	schedule = {},
	warningDialog = false,
	setSchedule = () => {},
	setWarningDialog = () => {},
}): JSX.Element => {
	return (
		<Modal
			isOpen={warningDialog}
			onClose={() => {
				setWarningDialog(false);
			}}
			size={"lg"}
		>
			<Grid
				container
				direction={"row"}
				justifyContent={"center"}
				alignItems={"center"}
				spacing={1}
			>
				<Grid
					item
				>
					<img
						src={WhiteAndBlueWarning}
						className={"pay-schedule-bureau-modal-warning-img"}
						alt={"white-and-blue-warning"}
					/>
				</Grid>
				<Grid item>
					<StyleTypography
						fontSize={35}
						fontSizeMedium={28}
						fontWeight={'bold'}
					>
						Warning
					</StyleTypography>
				</Grid>

			</Grid>

			<Grid
				container
				textAlign={"center"}
				marginTop={"10px"}
				marginBottom={"50px"}
			>
				<StyleTypography
					fontSize={18}
					fontSizeMedium={15}
					color={'black'}
				>
					Enabling automatic pay scheduling means that on the selected pay date,
					FreePayroll will automatically process payroll and salaries according to the schedule
					without requiring further confirmation. Additionally, the Full Payment Submission (FPS)
					will be automatically submitted to HMRC. Please ensure all employee details and payment
					amounts are accurate before activating this feature.
				</StyleTypography>
			</Grid>

			<div
				className={"pay-schedule-free-payroll-button-style"}
			>
				<FreepayrollButton
					onClick={() => setWarningDialog(false)}
					variant={"primary"}
					size={"medium"}
				>
					I understand
				</FreepayrollButton>
				<FreepayrollButton
					variant={"outline"}
					size={"medium"}
					onClick={() => {
						schedule.automaticPaySchedule = false;
						setSchedule(schedule);
						setWarningDialog(false);
					}}
				>
					Cancel
				</FreepayrollButton>
			</div>
		</Modal>
	);
}

export default RunPayScheduleAutomaticDialog;