import Grid from "@mui/material/Grid";
import React, {useState} from "react";
import Divider from "@mui/material/Divider";
import 'react-calendar/dist/Calendar.css';
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import {FreepayrollButton, FreepayrollTextField, Typography} from "@collegia-partners/ui-kit";
import {PayScheduleConfirmDialog} from "../../PaySchedule/PayScheduleConfirmDialog/PayScheduleConfirmDialog";
import {useNavigate} from "react-router-dom";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PayScheduleBureauClientConfirmation = ({
	setStep = () => {},
	addPaySchedule = () => {},
	schedule = {},
	setSchedule = () => {},
	isOnboarding = false,
}): JSX.Element => {

	const push = useNavigate();
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

	return (
		<Grid
			container
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
			>
				<Grid>
					<Typography variant={"title"} size={"large"} color={"primary"} weight={"bold"}>
						Create a new pay schedule
					</Typography>
				</Grid>
			</Grid>
			<Grid
				paddingTop={"30px"}
				paddingBottom={"50px"}
				width={"100%"}
			>
				<Divider/>
			</Grid>

			{/*Pay Schedule Information*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				direction={"row"}
				alignContent={"flex-start"}
				spacing={4}
			>
				<Grid
					xl={4.5}
					lg={4.5}
					md={6.5}
					sm={6.5}
					xs={6.5}
					item
				>
					<Grid>
						<Typography variant={"body"} size={"medium"} color={"black"} weight={"bold"}>
							Send pay run for client confirmation
						</Typography>
						<Typography variant={"body"} size={"small"} color={"gray"} weight={"normal"}>
							You have the option of sending prepared pay run to the client for their review and
							confirmation.
							This allows the client to verify all employee details and payment amounts before payroll is
							finalised.
						</Typography>
					</Grid>
					<Grid marginTop={"1vw"}>
						<FormControlLabel
							id={"pay-schedule-switch"}
							control={
								<Switch
									sx={{marginLeft: 'auto'}}
									checked={schedule?.clientAuthorisation}
									onClick={() => {
										schedule.clientAuthorisation = !schedule.clientAuthorisation;
										schedule.authoriserName = "";
										schedule.authoriserEmail = "";
										setSchedule(schedule);
									}}
								/>
							}
							label={
								<Typography variant={"body"} size={"medium"} color={"black"} weight={"bold"}>
									Client authorisation not required
								</Typography>
							}
							sx={{marginLeft: 0}}
						/>
					</Grid>
				</Grid>
			</Grid>
			{
				schedule?.clientAuthorisation &&
				<Grid
					marginTop={"2.5vw"}
					container
					alignContent={"flex-start"}
				>
					<Grid marginBottom={"10px"}>
						<Typography variant={"body"} size={"medium"} color={"black"} weight={"bold"}>
							Please add the client details below
						</Typography>
					</Grid>

					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						item
						container
						direction={"row"}
						alignContent={"flex-start"}
						spacing={4}
					>
						{/*Name*/}
						<Grid
							xl={4.5}
							lg={4.5}
							md={6.5}
							sm={6.5}
							xs={6.5}
							item
						>
							<InputLabel
								className={"default-input-label"}
							>
								Full Name
							</InputLabel>
							<FreepayrollTextField
								placeholder={"Type authoriser full name"}
								name={"authorisedName"}
								fieldType={"text"}
								value={schedule.authoriserName}
								onChange={(e) => {
									schedule.authoriserName = e.target.value;
									setSchedule(schedule);
									if (!schedule.clientAuthorisation) {
										schedule.authoriserName = "";
										schedule.authoriserEmail = "";
										setSchedule(schedule);
									}
								}}
								noGhostLabel
								noGhostHelperText
							/>
						</Grid>

						{/*Email*/}
						<Grid
							xl={4.5}
							lg={4.5}
							md={6.5}
							sm={6.5}
							xs={6.5}
							item
						>
							<InputLabel
								className={"default-input-label"}
							>
								Email
							</InputLabel>
							<FreepayrollTextField
								placeholder={"Type authoriser email"}
								name={"authorisedEmail"}
								fieldType={"text"}
								value={schedule.authoriserEmail}
								onChange={(e) => {
									schedule.authoriserEmail = e.target.value;
									setSchedule(schedule);
								}}
								noGhostLabel
								noGhostHelperText
							/>
						</Grid>
					</Grid>
				</Grid>
			}
			<div className={"pay-schedule-information-button-2"}>
				<FreepayrollButton
					variant={"primary"}
					size={"medium"}
					onClick={() => {
						if (schedule?.clientAuthorisation) {
							if (schedule.authoriserName.length === 0 || schedule.authoriserEmail.length === 0) {
								alert("Please fill in the authoriser name and email");
							} else {
								setStep(3);
							}
						} else {
							setOpenConfirmDialog(true);
						}
					}}
					disabled={(schedule?.clientAuthorisation && (schedule.authoriserName.length === 0 ||
						schedule.authoriserEmail.length === 0))}
				>
					Continue
				</FreepayrollButton>
			</div>
			<PayScheduleConfirmDialog
				open={openConfirmDialog}
				schedule={schedule}
				setOpen={setOpenConfirmDialog}
				addPaySchedule={addPaySchedule}
				isOnboarding={isOnboarding}
				push={push}
				isBureau={true}
			/>
		</Grid>
	);
}

export default PayScheduleBureauClientConfirmation;