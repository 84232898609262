import {
	BrowserRouter as Router,
	Navigate,
	Route,
	Routes as RouterRoutes,
} from "react-router-dom";

import React, { useEffect } from "react";

import Pages from "../pages";
import { retrieveAccessToken } from "./Helpers";
import Page404 from "../pages/Errors/Page404";
import { Crisp } from "crisp-sdk-web";

function renderRoute(sectionRoutes, authed, MainPageWrapper) {
	return Object.entries(sectionRoutes).map(([path, component]) => (
		<Route
			key={path}
			path={path}
			element={
				authed ? (
					<MainPageWrapper>
						{React.createElement(component, {
							isOnboarding: path.includes("onboarding"),
						})}
					</MainPageWrapper>
				) : (
					<Navigate to="/login" />
				)
			}
		/>
	));
}

const makeRoutes = () => {
	const MainPageWrapper = ({ children }) => {
		useEffect(() => {
			Crisp.load();
			Crisp.session.setData({
				auth_token: localStorage.getItem("fpEmployerToken"),
			});
			// Call the function when the component mounts
		}, []);

		return <>{children}</>; // Render the wrapped content
	};

	const authed = retrieveAccessToken() ?? false;

	const publicPaths = {
		"/": Pages.Login,
		"/login": Pages.Login,
		"/sign-up": Pages.SignUp,
		"/forgot-password": Pages.ForgotPassword,
		"/404": Pages.ErrorPages.Page404,
		"/error": Pages.ErrorPages.ErrorPage,
		"/auto-enrolment/:token": Pages.AutoEnrolment,
		"/payroll-approval/:token": Pages.PayrollPages.PayrollApproval,
		"/payroll-approval-completed/:token": Pages.PayrollPages.CompletedPayrollApproval,
	};

	const onboardingRoutes = {
		"/confirm-email": Pages.ConfirmEmail,
		"/company-details": Pages.OnboardingPages.CompanyDetails,
		"/onboarding/dashboard": Pages.OnboardingPages.OnboardingDashboard,
		"/onboarding/basic-setup": Pages.OnboardingPages.BasicSetup,
		"/onboarding/pay-schedule": Pages.CompanyPages.AddPaySchedule,
		"/onboarding/add-employee-method": Pages.PeoplePages.AddEmployeeMethod,
		"/onboarding/add-employee": Pages.PeoplePages.AddSingleEmployeeMain,
		"/onboarding/add-bulk-employees": Pages.PeoplePages.BulkEmployees,
	};

	const payrollSectionRoutes = {
		"/main/run-payroll/:payRunId": Pages.PayrollPages.RunPayroll,
		"/main/completed-payroll": Pages.PayrollPages.CompletedPayroll,
		"/main/view-payroll/:payRunId": Pages.PayrollPages.ViewPayroll,
		"/main/payroll/payroll-active": Pages.PayrollPages.PayrollActive,
		"/main/payroll/payroll-history": Pages.PayrollPages.PayrollHistory,
		"/main/payroll/payroll-archived": Pages.PayrollPages.PayrollArchive,
	};

	const companySectionRoutes = {
		"/main/company/pay-schedules": Pages.CompanyPages.PaySchedules,
		"/main/company/add-pay-schedule": Pages.CompanyPages.AddPaySchedule,
		"/main/company/edit-pay-schedule/:scheduleId":
			Pages.CompanyPages.EditPaySchedule,
		"/main/company/allowances": Pages.CompanyPages.Allowances,
		"/main/company/pay-items": Pages.CompanyPages.ListPayItems,
		"/main/company/create-pay-item": Pages.CompanyPages.PayItems,
		"/main/company/salaries": Pages.CompanyPages.Salaries,
		"/main/company/details": Pages.CompanyPages.Details,
		"/main/company/profile": Pages.CompanyPages.CompanyProfile,
		"/main/company/enhanced-policy": Pages.CompanyPages.EnhancedPolicy,
		"/main/company/statutory": Pages.CompanyPages.Statutories,
	};

	const hmrcSectionRoutes = {
		"/main/hmrc-settings": Pages.HMRC,
	};

	const autoEnrolmentSectionRoutes = {
		"/main/auto-enrolment-menu": Pages.AEPages.AutomaticEnrolmentMenu,
		"/main/auto-enrolment-settings": Pages.AEPages.AutomaticEnrolmentSettings,
		"/main/auto-enrolment-payments": Pages.AEPages.AutomaticEnrolmentPayments,
		"/main/auto-enrolment-ddi": Pages.AEPages.AutomaticEnrolmentDdi,
	};

	const peopleSectionRoutes = {
		"/main/people/team-members": Pages.PeoplePages.TeamMembers,
		"/main/people/record/:employeeId": Pages.PeoplePages.EmployeeRecord,
		"/main/people/add-employee-method": Pages.PeoplePages.AddEmployeeMethod,
		"/main/people/add-employee": Pages.PeoplePages.AddSingleEmployeeMain,
		"/main/people/finish-employee-setup/:employeeId":
			Pages.PeoplePages.AddSingleEmployeeMain,
		"/main/people/add-bulk-employees": Pages.PeoplePages.BulkEmployees,
	};

	const reportsSectionRoutes = {
		"/main/reports": Pages.ReportsPage.ReportsList,
		"/main/reports/p60": Pages.ReportsPage.ReportsP60,
		"/main/reports/p11": Pages.ReportsPage.ReportsP11,
		"/main/reports/eps": Pages.ReportsPage.ReportsEps,
		"/main/reports/fps": Pages.ReportsPage.ReportsFps,
		"/main/reports/payroll-journals": Pages.ReportsPage.PayrollJournal,
		"/main/reports/taxes-filings": Pages.ReportsPage.ReportsTaxesFilings,
	};

	const settingsSectionRoutes = {
		"/main/settings/edit-info": Pages.SettingsPages.EditPersonalInfo,
		"/main/settings/edit-email": Pages.SettingsPages.EditEmail,
		"/main/settings/edit-password": Pages.SettingsPages.EditPassword,
		"/main/settings/verify-account": Pages.SettingsPages.VerifyAccount,
	};

	const privatePaths = {
		//Main Routes
		"/main": Pages.MainPage,
		"/main/dashboard": Pages.MainPage,
		"/main/tutorials": Pages.Tutorials,
		"/main/close-tax-year": Pages.CloseTaxYear,
	};

	return [
		...Object.entries(publicPaths).map(([path, component]) => (
			<Route
				key={path}
				path={path}
				element={React.createElement(component, {})}
			/>
		)),
		...renderRoute(onboardingRoutes, authed, MainPageWrapper),
		...renderRoute(privatePaths, authed, MainPageWrapper),
		...renderRoute(payrollSectionRoutes, authed, MainPageWrapper),
		...renderRoute(companySectionRoutes, authed, MainPageWrapper),
		...renderRoute(hmrcSectionRoutes, authed, MainPageWrapper),
		...renderRoute(autoEnrolmentSectionRoutes, authed, MainPageWrapper),
		...renderRoute(peopleSectionRoutes, authed, MainPageWrapper),
		...renderRoute(reportsSectionRoutes, authed, MainPageWrapper),
		...renderRoute(settingsSectionRoutes, authed, MainPageWrapper),
		<Route path={"*"} key={"404"} element={<Page404 />} />,
	];
};

const Routes = () => (
	<Router>
		<RouterRoutes>{makeRoutes()}</RouterRoutes>
	</Router>
);

export default Routes;
