import Grid from "@mui/material/Grid";
import React, {useState} from "react";
import Divider from "@mui/material/Divider";
import 'react-calendar/dist/Calendar.css';
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useNavigate} from "react-router-dom";
import {FreepayrollButton, Typography} from "@collegia-partners/ui-kit";
import {PayScheduleConfirmDialog} from "../../PaySchedule/PayScheduleConfirmDialog/PayScheduleConfirmDialog";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PayScheduleBureauClientAutomaticClosePayRun = ({
	addPaySchedule = () => { },
	schedule = {},
	setSchedule = () => {},
	isOnboarding,
}): JSX.Element => {
	const push = useNavigate();
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	return (
		<Grid
			container
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
			>
				<Grid>
					<Typography variant={"title"} size={"large"} color={"primary"} weight={"bold"}>
						Create a new pay schedule
					</Typography>
				</Grid>
			</Grid>
			<Grid
				paddingTop={"30px"}
				paddingBottom={"50px"}
				width={"100%"}
			>
				<Divider />
			</Grid>

			{/*Automatically Close Pay Run*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				direction={"row"}
				alignContent={"flex-start"}
				spacing={4}
			>
				<Grid
					xl={6}
					lg={6}
					md={6.5}
					sm={6.5}
					xs={6.5}
					item
				>
					<Grid>
						<Typography variant={"body"} size={"medium"} color={"black"} weight={"bold"}>
							Automatically close pay run upon client authorisation
						</Typography>
						<Typography variant={"body"} size={"small"} color={"gray"} weight={"normal"}>
							Once the client authorises the pay run, it can be automatically closed
						</Typography>
						<Typography variant={"body"} size={"small"} color={"gray"} weight={"normal"}>
							and finalised without
							further action needed from you. This ensures
						</Typography>
						<Typography variant={"body"} size={"small"} color={"gray"} weight={"normal"}>
							a seamless and efficient payroll process.
						</Typography>
					</Grid>
					<Grid marginTop={"1vw"}>
						<FormControlLabel
							id={"pay-schedule-switch"}
							control={
								<Switch
									sx={{ marginLeft: 'auto' }}
									checked={schedule?.closePayRun}
									onClick={() => {
										schedule.closePayRun = !schedule.closePayRun;
										setSchedule(schedule);
									}}
								/>
							}
							label={
								<Typography variant={"body"} size={"medium"} color={"black"} weight={"bold"}>
									I will close the pay run
								</Typography>
							}
							sx={{marginLeft: 0}}
						/>
					</Grid>
				</Grid>
			</Grid>

			<Grid
				container
				direction={"row"}
				marginTop={"50px"}
				alignItems={"center"}
				id={"pay-schedule-automatically-close-note"}
			>
				<Grid>
					<Typography variant={"body"} size={"small"} color={"black"} weight={"bold"}>
						Note:
					</Typography>
				</Grid>
				<Grid marginLeft={"5px"}>
					<Typography variant={"body"} size={"small"} color={"black"} weight={"normal"}>
						When enabled, the pay run will be completed immediately after client authorisation,
						and payroll will be processed accordingly.
					</Typography>
				</Grid>
			</Grid>
			<div className={"pay-schedule-information-button-2"}>
				<FreepayrollButton
					variant={"primary"}
					size={"medium"}
					onClick={() =>
						setOpenConfirmDialog(true)
					}
				>
					Save
				</FreepayrollButton>
			</div>

			<PayScheduleConfirmDialog
				open={openConfirmDialog}
				schedule={schedule}
				setOpen={setOpenConfirmDialog}
				addPaySchedule={addPaySchedule}
				isOnboarding={isOnboarding}
				push={push}
				isBureau={true}
			/>
		</Grid>
	);
}

export default PayScheduleBureauClientAutomaticClosePayRun;