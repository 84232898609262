import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Check from "../../../../images/svg/check.svg";
import {FreepayrollButton, Typography} from "@collegia-partners/ui-kit";
import React from "react";

const checkItems = (text = ""): JSX.Element => {
	return (
		<Grid
			container
			direction={"row"}
			paddingBottom={"22px"}
			alignItems={"center"}
			display={"flex"}
			flexWrap={"nowrap"}
		>
			<Grid
				item
				width={"20px"}
				height={"20px"}
				marginTop={"5px"}
			>
				<img
					src={Check}
					alt={"check"}
				/>
			</Grid>
			<Grid
				item
				paddingLeft={"8px"}
			>
				<Typography variant={"body"} size={"medium"} color={"black"} weight={"normal"}>
					{text}
				</Typography>
			</Grid>

		</Grid>
	);
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const FirstInformation = ({
	setStep = () => {
	}
}): JSX.Element => {

	return (
		<Grid
			container
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
			>
				<Grid>
					<Typography variant={"title"} size={"large"} color={"primary"} weight={"bold"}>
						Add Pay Schedule
					</Typography>
				</Grid>
			</Grid>
			<Grid
				paddingTop={"30px"}
				paddingBottom={"50px"}
				width={"100%"}
			>
				<Divider/>
			</Grid>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
			>
				<Grid
					xl={6}
					lg={6}
					md={6}
					sm={6}
					xs={6}
					item
				>
					<Grid>
						<Typography variant={"body"} size={"xmedium"} color={"black"} weight={"bold"}>
							Why do we need to ask for this?
						</Typography>
					</Grid>
					<Grid
						paddingTop={"15px"}
					>
						<Typography variant={"body"} size={"small"} color={"gray"} weight={"normal"}>
							Pay schedules refer to the regular intervals at which your
							workers receive their wages or salaries. In other words,
							it is how often you pay them. You can set up more than one pay schedule,
							but each worker can be assigned to only one pay schedule.
						</Typography>
					</Grid>
					<Grid
						width={"250px"}
						height={"60px"}
						paddingTop={"50px"}
					>
						<FreepayrollButton
							variant={"primary"}
							size={"medium"}
							onClick={() => setStep(1)}
						>
							Add Pay Schedule
						</FreepayrollButton>
					</Grid>
				</Grid>
				<Grid
					xl={5}
					lg={5}
					md={5}
					sm={5}
					xs={5}
					item
					paddingLeft={"180px"}
				>
					<Grid
						paddingBottom={"17px"}
					>
						<Typography variant={"body"} size={"xmedium"} color={"black"} weight={"bold"}>
							Payroll review and approval
						</Typography>
					</Grid>
					{checkItems("Pay period")}
					{checkItems("Pay dates")}
					{checkItems("Desired date of first payroll with FreePayroll")}
				</Grid>
			</Grid>
		</Grid>
	);
}

export default FirstInformation;