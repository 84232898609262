import React, {useState} from "react";
import {Divider, FreepayrollButton, Typography, Checkbox, FreepayrollSelect, Modal} from "@collegia-partners/ui-kit";
import Request from "../../utils/Request";
import * as XLSX from "xlsx";
import FileDownload from "js-file-download";
import {toast} from "react-toastify";
import Skeleton from "../Skeleton";
import {formatDateFromBackendWithTime, formatMoney} from "../../utils/Helpers";
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";

function getApprovalStatus (approvalStatus) {
	if (approvalStatus === null) {
		return "Pending";
	} else if (approvalStatus === true) {
		return "Approved";
	} else if (approvalStatus === false) {
		return "Rejected";
	} else {
		return "Not requested";
	}
}


type PayrollSummaryProps = {
	payrollMode: "RUN_PAYROLL" | "VIEW_PAYROLL" | "PAYROLL_APPROVAL";
	onApprovePayroll?: () => void;
	onRejectPayroll?: () => void;
	payrollSummary: {
		net_pay: number,
		total_pension_deducted: number,
		total_taxes: number,
		base_pay_amount: number,
		gross_additions: number,
		net_additions: number,
		gross_deductions: number,
		income_tax_deducted: number,
		student_loan: number,
		pg_loan: number,
		employee_ni_deducted: number,
		employee_pension_deducted: number,
		net_deductions: number,
		employer_ni_deducted: number,
		employer_pension_deducted: number,
		total_payroll_cost: number,
		number_of_employees: number,
	};
	payrollId: string;
	isLoading: boolean;
	payScheduleRun: {};
}


/**
 * @returns {JSX.Element}
 * @constructor
 */
const PayrollSummary = ({
    payrollMode,
    onApprovePayroll,
    onRejectPayroll,
    payrollSummary = {
        net_pay: 0,
        total_pension_deducted: 0,
        total_taxes: 0,
        base_pay_amount: 0,
        gross_additions: 0,
        net_additions: 0,
        gross_deductions: 0,
        income_tax_deducted: 0,
        student_loan: 0,
        pg_loan: 0,
        employee_ni_deducted: 0,
        employee_pension_deducted: 0,
        net_deductions: 0,
        employer_ni_deducted: 0,
        employer_pension_deducted: 0,
        total_payroll_cost: 0,
        number_of_employees: 0,
    },
    payrollId,
    isLoading,
    payScheduleRun,
	user,
}: PayrollSummaryProps): JSX.Element => {

	return (
		<div id={"Payroll-Summary"}>
			<PaymentSummary
				payrollMode={payrollMode}
				payrollSummary={payrollSummary}
				isLoading={isLoading}
				onApprovePayroll={onApprovePayroll}
				onRejectPayroll={onRejectPayroll}
			/>
			<BoxSummary
				payrollMode={payrollMode}
				onApprovePayroll={onApprovePayroll}
				onRejectPayroll={onRejectPayroll}
				payrollSummary={payrollSummary}
				isLoading={isLoading}
				payrollId={payrollId}
				payScheduleRun={payScheduleRun}
				user={user}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const {Profile} = state;

	return {
		...Profile,
	};
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PayrollSummary);

const PaymentSummary = ({
	payrollMode,
	payrollSummary,
	isLoading,
	onApprovePayroll,
	onRejectPayroll,
}) => {
	return (
		<div className={"Summary"}>
			{
				payrollMode === "PAYROLL_APPROVAL" && (
					<div className={"InformationBox"}>
						<Typography variant={"subtitle"} size={"xmedium"} color={"black"}>Payroll review and
							approval</Typography>
						<Typography variant={"body"} className={"InfoText"}>
							Below is a summary of the current pay run.
							Please review the Employees tab to check individual payslips for each worker.
							Ensure all details are correct before approving or rejecting the pay run.
						</Typography>
						<div className={"ApproveButtons"}>
							<FreepayrollButton variant={"primary"} onClick={onApprovePayroll} >Approve</FreepayrollButton>
							<FreepayrollButton variant={"white-button"} onClick={onRejectPayroll}>Reject</FreepayrollButton>
						</div>
					</div>
				)
			}
			<div className={"PaymentsOverview"}>
				<Typography variant={"subtitle2"} size={"xmedium"} color={"black"}>Payment overview</Typography>
				{renderPaymentOverviewLine("Net pay", payrollSummary.net_pay, isLoading)}
				{renderPaymentOverviewLine("Pension", payrollSummary.total_pension_deducted, isLoading)}
				{renderPaymentOverviewLine("PAYE & NIC", payrollSummary.total_taxes, isLoading)}
				<Divider color="light-gray"/>
			</div>
			<div className={"PaymentsOverview"}>
				<div className={"BreakdownSections"}>
					<Typography variant={"subtitle"} size={"xmedium"} color={"black"}>Payment
						breakdown</Typography>
					<Typography variant={"subtitle"} size={"xmedium"} color={"primary"}>Additions</Typography>
					{renderPaymentOverviewLine("Base pay", payrollSummary.base_pay_amount, isLoading)}
					{renderPaymentOverviewLine("Gross additions", payrollSummary.gross_additions, isLoading)}
					{renderPaymentOverviewLine("Net additions", payrollSummary.net_additions, isLoading)}
				</div>
				<div className={"BreakdownSections"}>
					<Typography variant={"subtitle"} size={"xmedium"} color={"primary"}>Deductions*</Typography>
					{renderPaymentOverviewLine("Gross deductions", payrollSummary.gross_deductions, isLoading)}
					{renderPaymentOverviewLine("Income tax", payrollSummary.income_tax_deducted, isLoading)}
					{renderPaymentOverviewLine("Student loan", payrollSummary.student_loan, isLoading)}
					{renderPaymentOverviewLine("Postgraduate loan", payrollSummary.pg_loan, isLoading)}
					{renderPaymentOverviewLine("Employees NIC", payrollSummary.employee_ni_deducted, isLoading)}
					{renderPaymentOverviewLine("Employees pension", payrollSummary.employee_pension_deducted, isLoading)}
					{renderPaymentOverviewLine("Net deductions", payrollSummary.net_deductions, isLoading)}
					<Divider color="light-gray"/>
				</div>
				<div className={"BreakdownSections"}>
					{renderPaymentOverviewLine("Employer NIC", payrollSummary.employer_ni_deducted, isLoading)}
					{renderPaymentOverviewLine("Employer pension", payrollSummary.employer_pension_deducted, isLoading)}
					<Divider color="light-gray"/>
				</div>
				<div className={"BreakdownSections"}>
					{renderPaymentOverviewLine("Total payroll cost", payrollSummary.total_payroll_cost, isLoading)}
				</div>
			</div>
		</div>
	);

};

const BoxSummary = ({
	payrollMode,
	onApprovePayroll,
	onRejectPayroll,
	payrollSummary,
	isLoading,
	payrollId,
	payScheduleRun,
	user,
}) => {

	const [openSubmitPayroll, setOpenSubmitPayroll] = useState(false);
	const [openRequestApproval, setOpenRequestApproval] = useState(false);

	const [downloadLoading, setDownloadLoading] = useState(false);
	const [isLate, setIsLate] = useState(false);
	const [lateReason, setLateReason] = useState(null);

	const downloadPaymentBreakdown = async () => {
		setDownloadLoading(true);
		// Download the payment breakdown
		try {
			const {data} = await Request.get(`/api/employers/view-payment-breakdown?id=${payrollId}`);
			const worksheet = XLSX.utils.json_to_sheet(data.data);
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
			const excelFileContent = XLSX.write(workbook, {type: "buffer", bookType: "xlsx"});

			FileDownload(
				excelFileContent,
				`payment-breakdown.xlsx`
			);
		} catch (e) {
			toast.error("An error occurred while downloading payment breakdown, please contact support");
			console.error(e);
		} finally {
			setDownloadLoading(false);
		}
	}

	return (
		<>
			<div className={"BoxSummary"}>
				<div className={"FirstBox"}>
					<Typography variant={"subtitle"} color={"black"}>To be transferred</Typography>
					{
						isLoading ? (
							<Skeleton width={'100%'} height={'3vw'} background={'#D6D8E1'}/>
						) : (
							<Typography variant={"subtitle"} size={"large"}
							            color={"black"}>{formatMoney(payrollSummary.net_pay)}</Typography>
						)
					}
					<Typography variant={"body"}>This is the total amount you have to
						pay your employees, the total net pay.</Typography>
					{
						payrollMode !== "PAYROLL_APPROVAL" && (
							!downloadLoading && (
								<div onClick={() => downloadPaymentBreakdown()}>
									<Typography variant={"subtitle2"} color={"black"} className={"ViewPayments"}>View payments
										due</Typography>
								</div>
							)
						)
					}
					<Divider color="light-gray"/>
				</div>
				<div className={"FirstBox"}>
					<Typography variant={"subtitle"} color={"black"}>Pension</Typography>
					{
						isLoading ? (
							<Skeleton width={'100%'} height={'3vw'} background={'#D6D8E1'}/>
						) : (
							<Typography variant={"subtitle"} size={"large"}
							            color={"black"}>{formatMoney(payrollSummary.total_pension_deducted)}</Typography>
						)
					}
					<Typography variant={"body"}>
						The pension contribution will be collected automatically.
						It will show in your account as Nuapay Re Collegia.
					</Typography>
					<Divider color="light-gray"/>
				</div>
				<div className={"FirstBox"}>
					<Typography variant={"subtitle"} color={"black"}>Employees</Typography>
					{
						isLoading ? (
							<Skeleton width={'100%'} height={'3vw'} background={'#D6D8E1'}/>
						) : (
							<Typography variant={"subtitle"} size={"large"}
							            color={"black"}>{payrollSummary.number_of_employees}</Typography>
						)
					}
				</div>
				{
					payrollMode === "PAYROLL_APPROVAL" && (
						<div className={"FirstBox"}>
							<Divider color="light-gray"/>
							<FreepayrollButton variant={"primary"} onClick={onApprovePayroll}>Approve</FreepayrollButton>
							<FreepayrollButton variant={"white-button"} onClick={onRejectPayroll}>Reject</FreepayrollButton>
						</div>
					)
				}
				{
					payrollMode === "RUN_PAYROLL" && (
						<div className={"FirstBox"}>
							{
								(user?.is_bureau_user && payScheduleRun?.pay_schedule?.require_client_authorisation && !payScheduleRun?.requested_approval) ? (
									<FreepayrollButton
										onClick={() => setOpenRequestApproval(true)}
										variant={"primary"}
									>
										Request approval
									</FreepayrollButton>
								) : (
									<>
										<Checkbox
											label="This submission is after the pay date"
											isChecked={isLate}
											onChange={(checked) => setIsLate(checked)}
										/>
										{
											isLate && (
												<FreepayrollSelect
													options={[
														{
															label: 'A - Payment to expat by third party or overseas employer',
															value: 'A'
														},
														{
															label: 'B - Employment related security',
															value: 'B'
														},
														{
															label: 'C - Notional payment: Other',
															value: 'C'
														},
														{
															label: 'D - Payment subject to Class 1 NICs but P11/P9D for tax',
															value: 'D'
														},
														{
															label: 'E - Micro employer using temporary ‘on or before’ relaxation',
															value: 'E'
														},
														{
															label: 'F - Impractical to report work done on the day',
															value: 'F'
														},
														{
															label: 'G - Reasonable excuse',
															value: 'G'
														},
														{
															label: 'H - Correction to earlier submission',
															value: 'H',
															disabled: !payScheduleRun?.fps_submitted
														}
													]}
													onSelect={(value) => setLateReason(value?.value || null)}
													label="Select late reason"
													noGhostHelperText
												/>
											)
										}
										<FreepayrollButton
											variant={"primary"}
											disabled={isLate && !lateReason}
											onClick={() => setOpenSubmitPayroll(true)}
										>
											Submit
										</FreepayrollButton>
									</>
								)
							}
						</div>
					)
				}
			</div>
			{
				payrollMode === "RUN_PAYROLL" && (
					<>
						<ConfirmPayrollModal
							isOpen={openSubmitPayroll}
							onClose={() => setOpenSubmitPayroll(false)}
							payScheduleRun={payScheduleRun}
							isLate={isLate}
							lateReason={lateReason}
							payrollId={payrollId}
							user={user}
						/>
						<RequestApproval
							isOpen={openRequestApproval}
							onClose={() => setOpenRequestApproval(false)}
							payScheduleRun={payScheduleRun}
							payrollId={payrollId}
							payrollSummary={payrollSummary}
						/>
					</>
				)
			}
		</>
	);
};

const ConfirmPayrollModal = ({
	isOpen, onClose, payScheduleRun, isLate, lateReason, payrollId,
}) => {

	const [submissionLoading, setSubmissionLoading] = useState(false);
	const [forceSubmission, setForceSubmission] = useState(false);
	const push = useNavigate();

	const submitPayroll = async () => {
		try {
			setSubmissionLoading(true);
			const {data} = await Request.post('/api/employers/confirm-payroll', {
				"pay_schedule_runs": {
					id: payrollId,
					is_late: isLate,
					late_reason: lateReason,
				}
			});

			if (data.success) {
				push(`/main/completed-payroll?pay_schedule=${payScheduleRun?.pay_schedule?.name}&tax_period=${payScheduleRun?.tax_period}&pay_date=${formatDateFromBackendWithTime(payScheduleRun?.period_end_date)}&id=${payrollId}`);
			}
		} catch (e) {
			if (e.response.status === 422) {
				toast.error(e.response.data.message);
			}
			console.error(e);
		} finally {
			setSubmissionLoading(false);
		}
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} size={"sm"}>
			<div id={"ApprovePayrollContainer"}>
				<Typography variant={"title"} color={"primary"} className={"ModalTitle"}> Confirm Payroll </Typography>
				<Typography variant={"body"} size={"xmedium"} color={"black"}>• Tax
					Period: {payScheduleRun?.tax_period}</Typography>
				<Typography variant={"body"} size={"xmedium"} color={"black"}>• Pay
					Date: {formatDateFromBackendWithTime(payScheduleRun?.period_end_date)}</Typography>
				<Typography variant={"body"} size={"xmedium"} color={"black"}>• Tax
					Year: {payScheduleRun?.tax_year?.formated_tax_year}</Typography>
				{
					isLate && (
						<Typography variant={"body"} size={"xmedium"} color={"black"}>• Late
							Reason: {lateReason}</Typography>
					)
				}
				{
					payScheduleRun.pay_schedule?.require_client_authorisation && (
						<Typography variant={"body"} size={"xmedium"} color={"black"}>
							• Approval status: {getApprovalStatus(payScheduleRun?.pay_schedule_run_approval?.is_approved)}
						</Typography>
					)
				}
				{
					(
						payScheduleRun.pay_schedule?.require_client_authorisation &&
						payScheduleRun?.pay_schedule_run_approval?.is_approved !== true
					) && (
						<Checkbox
							label="I want to submit this payroll without client approval"
							isChecked={forceSubmission}
							onChange={(checked) => setForceSubmission(checked)}
							customLabelClassName={"ForceSubmissionLabel"}
							style={{width: '1vw'}}
						/>
					)

				}
				<div className={"ApproveButtonsContainer"}>
					<FreepayrollButton
						variant={"outline"}
						size={"small"}
						onClick={onClose}
						isLoading={submissionLoading}
					>
						Cancel
					</FreepayrollButton>
					<FreepayrollButton
						variant={"primary"}
						size={"small"}
						onClick={submitPayroll}
						isLoading={submissionLoading}
						disabled={
							payScheduleRun?.pay_schedule?.require_client_authorisation &&
							getApprovalStatus(payScheduleRun?.pay_schedule_run_approval?.is_approved) !== "Approved"
							&& !forceSubmission
						}
					>
						Submit
					</FreepayrollButton>
				</div>
			</div>
		</Modal>
	)
}

const RequestApproval = ({
	isOpen,
	onClose,
	payScheduleRun,
	payrollId,
	payrollSummary
}) => {

	const [approvalLoading, setApprovalLoading] = useState(false);

	const push = useNavigate();

	const requestPayrollApproval = async () => {
		try {
			setApprovalLoading(true);
			await Request.post('/api/employers/request-payroll-approval', {
				"pay_schedule_runs": {
					id: payrollId,
					numberOfEmployees: payrollSummary?.number_of_employees,
					totalPayrollAmount: payrollSummary?.total_payroll_cost,
				}
			});
			push(`/main`);
		} catch (e) {
			console.log(e.response.status < 500);
			if (e.response.status < 500) {
				toast.error(e.response.data.message);
			}
			console.error(e);
		} finally {
			setApprovalLoading(false);
		}
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} size={"md"}>
			<div id={"ApprovePayrollContainer"}>
				<Typography variant={"title"} color={"primary"} className={"ModalTitle"}> Request approval </Typography>
				<Typography variant={"body"} size={"xmedium"} color={"black"}>
					• Client name: {payScheduleRun?.pay_schedule?.authoriser_name}
				</Typography>
				<Typography variant={"body"} size={"xmedium"} color={"black"}>
					• Client email: {payScheduleRun?.pay_schedule?.authoriser_email}
				</Typography>

				<Typography variant={"body"} color={"black"}>
					<strong>Note:</strong> Client will receive an email to approve this payroll. You will be notified upon client's decision.
					Alternatively, you can submit the payroll without client approval, if you need to.
				</Typography>

				<div className={"ApproveButtonsContainer"}>
					<FreepayrollButton
						variant={"outline"}
						size={"small"}
						onClick={onClose}
						isLoading={approvalLoading}
					>
						Cancel
					</FreepayrollButton>
					<FreepayrollButton
						variant={"primary"}
						size={"small"}
						onClick={requestPayrollApproval}
						isLoading={approvalLoading}
					>
						Request approval
					</FreepayrollButton>
				</div>
			</div>
		</Modal>
	)
}

function renderPaymentOverviewLine(title, value, isLoading) {
	return (
		isLoading ? (
			<div className={"PaymentOverviewLine"}>
				<Skeleton width={'100%'} height={'2vw'}/>
			</div>
		) : (
			<div className={"PaymentOverviewLine"}>
				<Typography variant={"body"} size={"medium"} color={"black"} weight={"normal"}>{title}</Typography>
				<Typography variant={"subtitle"} size={"medium"} color={"black"}>{formatMoney(value ?? 0)}</Typography>
			</div>
		)
	);
}
