import Grid from "@mui/material/Grid";
import React, {useEffect, useReducer, useState} from "react";
import {StyleAppBar} from "../../../components/StyleAppBar";
import {me} from "../../../actions/Profile";
import {connect} from "react-redux";
import PayScheduleInformation from "../../../components/Pages/Company/PaySchedule/PayScheduleInformation";
import {addPaySchedule} from "../../../actions/PaySchedule";
import Loading from "../../../components/Loading";
import FirstInformation from "../../../components/Pages/Company/PaySchedule/FirstInformation";
import {LeftMenu} from "../../../components/LeftMenu";
import {findEmployer} from "../../../actions/Employer";
import PayScheduleBureauClientConfirmation from "../../../components/Pages/Company/PaySchedule/PayScheduleBureauClientConfirmation";
import PayScheduleBureauClientAutomatiClosePayRun
	from "../../../components/Pages/Company/PaySchedule/PayScheduleBureauClientAutomaticClosePayRun";

const stepsPaySchedule  = (
	step = 0,
	setStep = () => {},
	addSchedule,
	employer,
	isOnboarding,
	user = {},
	schedule = {},
	setSchedule = () => {},
): JSX.Element => {
	switch (step) {
		case 0:
			return (
				<FirstInformation
					setStep={setStep}
				/>
			);
		case 1:
			return (
				<PayScheduleInformation
					addPaySchedule={addSchedule}
					employer={employer}
					isOnboarding={isOnboarding}
					user={user}
					setStep={setStep}
					schedule={schedule}
					setSchedule={setSchedule}
				/>
			);
		case 2:
			return (
				<PayScheduleBureauClientConfirmation
					setStep={setStep}
					addPaySchedule={addSchedule}
					schedule={schedule}
					setSchedule={setSchedule}
					isOnboarding={isOnboarding}
				/>
			);
		case 3:
			return (
				<PayScheduleBureauClientAutomatiClosePayRun
					setStep={setStep}
					addPaySchedule={addSchedule}
					schedule={schedule}
					setSchedule={setSchedule}
					isOnboarding={isOnboarding}
				/>
			);
		default:
			return;
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const AddPaySchedule = ({
	addSchedule = () => {},
	me,
	user,
	user_loading,
	pay_schedule_loading,
	isOnboarding = false,
	findEmployer = () => {},
	employer,
}): JSX.Element => {
	const [step, setStep] = useState(0),
		[schedule, setSchedule] = useReducer(
		(state, updates) => ({
			...state,
			...updates,
		}),
		{
			scheduleName: "",
			payFrequencyOption: [
				{ name: "Weekly", code: "W1" },
				{ name: "Fortnightly", code: "W2" },
				{ name: "Four Weekly", code: "W4" },
				{ name: "Monthly", code: "M1" }
			],
			payFrequency: "Select Pay frequency",
			payDays: "Select Pay Day",
			dayRateMethod: "yearly_working_days",
			firstPayDate: null,
			taxPeriod: "",
			automaticPaySchedule: false,
			clientAuthorisation: false,
			closePayRun: false,
			authoriserName: "",
			authoriserEmail: "",
		});

	useEffect(() => {
		if (!user?.id) {
			me();
		}
		findEmployer(["tax_year"]);
		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [findEmployer, me, user?.id]);

	if (user_loading || pay_schedule_loading) {
		return <Loading/>;
	}

	return (
		<Grid
			id={"company-pay-schedules-page"}
			container
			justifyContent={"center"}
		>
			<StyleAppBar
				backLink={"/main/company/pay-schedules"}
				user={user}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={isOnboarding ? "Setup" : "Company"}
						isOnboarding={isOnboarding}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
					>
						{
							stepsPaySchedule(
								step,
								setStep,
								addSchedule,
								employer,
								isOnboarding,
								user,
								schedule,
								setSchedule
							)
						}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		PaySchedule,
		Employer,
	} = state;

	return {
		...Profile,
		...PaySchedule,
		...Employer
	}
}

const mapDispatchToProps = dispatch => ({
	me:  () => dispatch(me()),
	addSchedule: (data, isOnboarding, push) => dispatch(addPaySchedule(data, isOnboarding, push)),
	findEmployer: (relations = []) => dispatch(findEmployer(relations))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPaySchedule)